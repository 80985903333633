export function empty<T>(value: T): boolean {
    if (Array.isArray(value)) {
        return !value.length;
    }
    return value === null || value === undefined;
}

export function omitEmpty<T extends Record<string, unknown>>(options: T): Partial<T> {
    return Object.fromEntries(Object.entries(options)
        .filter(([, v]) => !empty(v))) as Partial<T>;
}

export function omitKeys<T extends object, K extends keyof T>(obj: T, properties: K[]): Partial<T> {
    return (Object.keys(obj) as K[]).reduce((acc: Partial<T>, key: K) => {
        return properties.includes(key) ? acc : {...acc, [key]: obj[key]};
    }, {});
}
